<template>
  <div>
    <div class="relative bg-yellow-100 bg-opacity-50">
      <img class="sm:hidden w-full" src="/img/mask.png" alt="Ask Us" />
      <div
        class="absolute inset-0 flex sm:items-center mt-8 sm:mt-0 justify-center"
      >
        <h2 class="font-bold text-3xl text-primary-orange">Ask Us</h2>
      </div>
      <img class="hidden sm:block w-full" src="/img/ask.png" alt="Ask Us" />
    </div>
    <div class="px-6 md:px-0 py-12 md:max-w-3xl md:mx-auto">
      <MultiSearch />
    </div>
    <div class="px-12 md:max-w-4xl md:mx-auto">
      <h2 class="mt-3 text-3xl font-semibold text-primary-orange text-center">
        Top Questions
      </h2>
      <div class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"></div>
      <p class="text-center text-sm">Frequently Asked Questions</p>
      <ul class="mt-6 list-disc space-y-4">
        <li>I wanted to complain against MLA, can I do that?</li>
        <li>
          I wanted to file an FIR, can I do that?
        </li>
        <li>
         I wanted to complain against a hotel management, where food is really compromised in quality, can I do that?
        </li>
        <li>
         I saw a child working, can I complain to police by calling without giving my details?
        </li>
        <li>
          My car was stopped by a traffic police, can I stop the car and sit inside the car without lowering the window?
        </li>
        <li>
          I see the MSRP price is not respected on the product and on sale for double the price. Whom can I
        </li>
      </ul>
    </div>
    <div class="py-12 px-6 md:px-0 md:max-w-2xl md:mx-auto">
      <h2 class="mt-3 text-3xl font-semibold text-primary-orange text-center">
        Still Need Help?
      </h2>
      <!-- <div class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"></div>
      <p class="text-center text-sm">
        Didn't find what you are looking for? Our Customer Care Specialists are
        also avialable to support you. Call our toll-free hotline number or
        message us through our social media.
      </p>
      <div
        class="mt-6 flex flex-col md:flex-row items-center justify-center md:space-x-6"
      >
        <div
          class="bg-primary-orange w-64 flex items-center justify-center space-x-4 rounded-lg shadow"
        >
          <p
            class="uppercase text-gray-900 font-bold text-center leading-tight"
          >
            Toll <br />
            Free
          </p>
          <p class="font-bold text-white text-2xl py-4">1 800 XX XX</p>
        </div>
        <div class="flex justify-center">
          <SocialLinks class="mt-6 md:mt-0" />
        </div>
      </div> -->
      <div class="mt-6 md:pt-6 text-center px-6 md:px-12 space-y-6">
        <!-- <p class="font-semibold uppercase text-secondary-gray">or</p> -->
        <Button @click="$router.push('/question')" class="w-full sm:w-auto"
          >Submit a Question</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MultiSearch from "@/components/MultiSearch";
import SocialLinks from "@/components/SocialLinks";

export default {
  components: {
    Button,
    MultiSearch,
    SocialLinks,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style></style>
